<template>
	<div>
		<div class="logoheader  login bg-f p-t-30" id="login" v-cloak>
			<header class="bg-f o-h h-120">
				<div class="pwidth  flex absolute  t--17 z-i-10" v-if="$store.state.app.Operational">
					
					<div  @click="()=>{$router.push({path:'/index'})}" class="flex  relative w-400">
						<div class="w-200 h-80 tc flex m-t-15"><img class="w100 m-a" :src="$store.state.app.logourl" alt="" /></div>
						<img class="" v-if="$store.state.app.Operational.header.logintext" :src="$store.state.app.Operational.header.logintext" alt="">
						<!-- <img class="" v-else src="../../assets/img/byu.png" alt=""> -->
						
						<!-- <div v-else class="p-l-20 ">
							
							<p class="iconfont b2b-a-goudijiagoudijia f-22 m-b-20 i-b c-3 "></p> 
							<p class="iconfont b2b-yipiao f-12 c-b14e"></p>
						</div> -->
					</div>
				
					<div class="flex w-400">
						<div class="c-3 h-50 w-300 m-t-40 tr">
							<p class="m-b-10">关注企业微信公众号</p>
							<p>更多优惠信息、消息通知及时获取</p>
						</div>
						<div class="QRcode"><img class="w-80  h-80" :src="$store.state.app.qrcodeurl" alt="" /></div>
					</div>
				</div>
			</header>
			
			<div class="h-600 relative loginbg" v-cloak>
				
					<div class="i-b absolute  m-a l-0 t-0 h-600 w100" :style="{background:linearGradient()}"></div>
			
					<div class="pwidth relative h100 ">
						<div class="flex-c s-c w60 h100" >
							<img src="../../assets/img/login-2.png" class="w-200 h-65 m-b-35" alt="">
							<div class="f-60 bold c-f m-b-25" style="letter-spacing:3px;">助力医药企业数智化转型</div>
							<div class="c-f f-22 l-h-30" style="letter-spacing:1px;">SUPPORTING THE SMART TRANSFORMATION OF PHARMACEUTICAL ENTERPRISES</div>
						</div>
						
						<div class="form bg-f rad-4 loginshow">
							<p class="f-30 tc p-t-40 p-b-30">欢迎登录</p>
							<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
								<el-form-item label="" prop="cardno" class="relative">
								
									<div class="absolute z-i-3 left-7">
										<i class="iconfont b2b-zhanghu c-9 f-20"></i>
									</div>
									
									<el-input icon="iconfont b2b-zhanghu" v-model="ruleForm.cardno" placeholder="请输入您的账号"></el-input>
								</el-form-item>

								<el-form-item label="" prop="checkPass" class="relative">
									<div class="absolute z-i-3 left-7"><i class="iconfont b2b-mima c-9 f-20"></i></div>
									<el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请输入您的密码"></el-input>
								</el-form-item>

								<el-form-item label="" prop="code" class="relative yzm">
									<div class="absolute z-i-3 left-7">	<i class="iconfont b2b-yanzhengma c-9 f-20"></i></div>
									<el-input v-model="ruleForm.code" placeholder="请输入验证码" @keyup.enter.native="submitForm('ruleForm')"></el-input>
									<div class="yzmimg" @click="authCodefn"><img :src="authCode" alt="" /></div>
								</el-form-item>

								<el-form-item class="m-t-40">
									<el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
									<div  @click="()=>{$router.push({path:'/register'})}"><p class="tc  cursor --color">免费注册</p></div>
								</el-form-item>
							</el-form>
						</div>
					</div>
				
			</div>
		</div>
		
		<customer :showcustomer="showpoup" ref="customer"/>
		
		<qualifications/>
	</div>
</template>

<script>

// var newslist = sessionStorage.getItem('newslist');
// require("page/Developer_Rui/header"); // var urlhost = 'kbyx.kingbos.com' //测试域名      线上window.location.host
import guidutil from '../../lib/guidutil.js'
import md5 from 'md5';
var guidobj = new guidutil();
import customer from '../../components/customer.vue';
export default {
	components:{customer},
	data() {
		var validatecardno = function validatecardno(rule, value, callback) {
			if (value === '') {
				this.options = [];
				callback(new Error('请输入账号'));
			} else {
				callback();
			}
		};

		var validatePass2 = function validatePass2(rule, value, callback) {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				callback();
			}
		};

		var checkcode = function checkcode(rule, value, callback) {
			if (!value) {
				return callback(new Error('验证码不能为空'));
			} else {
				callback();
			}
		};

		return {
			authCode: '',
			ruleForm: {
				cardno: '',
				checkPass: '',
				company: '',
				code: '',
				guid:'',
			},
			cardnonumber: '',
			rules: {
				cardno: [{ validator: validatecardno, trigger: 'blur' }],
				checkPass: [{validator: validatePass2,trigger: 'blur'}],
				code: [{validator: checkcode,trigger: 'blur'}]
			},
			options: [],
			tid: '',
			showpoup:false//显示弹窗
		};
	},
	
	created() {
		this.getTenantid(()=>{
			this.getRichtext()
			this.changeCodes()
		})

		// this.$serve.post('/b2bgoods/category',{})
		// .then(res => {
		//   let { data } = res.data;
		//   this.showitem = data;
		// });
	},
	
	methods: {
		
		submitForm: function submitForm(formName) {
			var _this3 = this;

			this.$refs[formName].validate(function(valid) {
				if (valid) {
					_this3.login();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm: function resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		
		// 验证码刷新
		authCodefn: function authCodefn() {
			this.changeCodes();
		},
		
		// 登录
		login: function login() {
			
			// this.$notify({ title: '成功', message: '登录成功', type: 'success',duration:0 });
			// return
			var {cardno,checkPass,company,code }=this.ruleForm		
			var Url = '/b2bmembercard/login';
			var data = {
				loginname: cardno,
				psw: md5(checkPass).toUpperCase(),
				// psw: hex_md5(checkPass),
				// psw: checkPass,
				guid: this.ruleForm.guid,
				authCode: code,
				url: this.urlhost,
			};
		
			this.logins(Url, data).then(res=> {
				if (res.status == 'success') {
					if (res.data.issaleman != 1) this.$notify({ title: '成功', message: '登录成功', type: 'success',duration:0 });
					
					if (res.data.issaleman == 1) 
						// headerTop.querymycustomer(); //选择客户
						this.showpoup=true
						this.$refs.customer.querymycustomer()
					
				} else {
					this.$notify.error({ title: '错误', message: res.message });
				}
			});

		},
		
		changeCodes() {
			// this.ruleForm.guid = guidobj.newGUID();
			// this.authCode=this.serve + '/authCode?guid=' + this.ruleForm.guid
			// this.authCode=this.serve + '/authCode?' + Math.random()
			// this.authCode = server + '/authCode?guid=' + guid;
			
			this.ruleForm.guid = guidobj.newGUID();
			this.authCode = this.serve + "/authCode?guid=" + this.ruleForm.guid;

		},
		
		 // this.$weibo.get(`/toutiao/xinwen?&token=${PUBLIC.getToken()}&page=${this.page}`)
		 //        .then(res => {
		 //          if (res.status == 200) {
		 //            let data = res.data;
		 //            // console.log(data)
		 //            // this.datatime = data[data.length - 1].behot_time;
		 //            // console.log("this.datatime", this.datatime);
		 //            for (let i in data) {
		 //              var img = [data[i].titlepic];
		 //              if (data[i].titlepic2 != "") img[1] = data[i].titlepic2
		 //              if (data[i].titlepic3 != "") img[1] = data[i].titlepic3
		 //              if (data[i].titlepic4 != "") img[1] = data[i].titlepic4
		 //              data[i]["img"] = img;
		 //            }
		 //            if (type) {
		 //              this.toutiaoList.push(...data);
		 //            } else {
		 //              this.toutiaoList = data;
		 //            }
		 //          }
		 //        })
		linearGradient(){
			console.log('this.$store.state.app',this.$store.state.app)
			let subjectcolor= this.$store.state.app.tenantshoppingmallconfig.subjectcolor
			return   `linear-gradient(270deg,${subjectcolor} 0%,${subjectcolor} 47%,rgba(0,177,78,0.6) 100%)`
		}
	}
};
</script>

<style>
	@import url("../../style/user/login.css");
	.loginshow{box-shadow: -1px 1px 10px rgba(0,0,0,0.16);}
	.loginshow .el-input__inner{border-left: 0;border-right: 0;border-top: 0;border-radius:0;}
	.loginshow .el-input__suffix {display: none;}
	.login .el-button--primary:hover{color: #fff !important;}
	
</style>
